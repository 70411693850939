<mat-card>
    <mat-card-title>Test</mat-card-title>
    <mat-card-content>
        <mat-grid-list cols="6" rowHeight="2:1" *ngFor="let conjugationClass of conjugationClasses">
            <mat-grid-tile>
                <app-conjugation-label [conjugationClass]="conjugationClass"></app-conjugation-label>
            </mat-grid-tile>
        </mat-grid-list>
    </mat-card-content>
</mat-card>
