<mat-toolbar color="primary" dir="rtl" class="position-fixed">
  <div fxFlex fxLayout fxLayoutAlign="center" >
    <h1>
      صــــــــــرف
    </h1>
  </div>
</mat-toolbar>
<main>
  <router-outlet>
    <div>
      <app-rootsearch></app-rootsearch>  
    </div>
  </router-outlet>  
</main>