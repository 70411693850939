<mat-card>
    <form class="root-search-form">
        <mat-grid-list cols="1" rowHeight="2:1">
            <mat-grid-tile>
                <mat-form-field class="root-form-full-width">
                    <input matInput placeholder="أدخل جذراً ثلاثياً أو رباعياً (فعل، مثلاً)"
                        [formControl]="rootFormControl" [errorStateMatcher]="matcher" maxlength="4" lang="ar"
                        minlength="3" dir="rtl" />

                    <mat-error *ngIf="!rootFormControl.hasError('required')">
                        الرجاء إدخال جذر كلمة عربية ثلاثي أو رباعي
                    </mat-error>
                    <mat-error *ngIf="rootFormControl.hasError('required')">
                        الجذر <strong>ضروري</strong>
                    </mat-error>
                </mat-form-field>
                <button mat-button type="submit">بحث</button>
            </mat-grid-tile>

            <mat-grid-tile>
                <app-conjugation-group [conjugationClasses]="conjugationClasses"></app-conjugation-group>
            </mat-grid-tile>
        </mat-grid-list>
    </form>
</mat-card>